import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

// import "./add-company.css";
import { IconContext } from "react-icons";
import styled from "styled-components";
import { CustomButton, CustomDateField, CustomTextField } from "../../../components";
import { useState } from "react";


interface ReconcileModalProps {
  body?: string | JSX.Element;
  open: boolean;
  amount: string;
  handleClose: () => void;
  updateData: any;
  onCancel?: () => void;
  loading?: boolean;
  heading?: string;
  conformButtonText?: string;
  cancelButtonText?: string;
  deleteButtonText?: string;
  isCancelButton?: boolean;
  padding?: number;
  deleteData?: () => void;
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #eee",
    borderRadius: "10px",
    padding: 0,
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
};

const ReconcileModal = (props: ReconcileModalProps) => {
  const {
    handleClose,
    onCancel,
    open,
    body,
    amount,
    updateData,
    loading = false,
    heading = "Please confirm",
    conformButtonText = "Confirm",
    cancelButtonText = "Close",
    deleteButtonText = "",
    isCancelButton = true,
    padding = 40,
    deleteData = () => {},
  } = props;

  const [transactionId, setTransactionId] = useState<string>("");
  const [paymentDate, setPaymentDate] = useState<string>("");

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div>
        <Header>
          <h3>{heading}</h3>
          <span onClick={handleClose}>
            <IconContext.Provider value={{ color: "red", size: "20px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </span>
        </Header>
        <Body style={{ padding: padding }}>

            <CustomTextField
              value={amount}
              onChangeText={setTransactionId}
              title="Amount:"
              disabled={true}
            />
            <CustomDateField
              label="Payment Received On:"
              date={paymentDate || null}
              setDate={setPaymentDate}
            />
            <CustomTextField
              value={transactionId}
              onChangeText={setTransactionId}
              title="Transaction ID:"
            />
        </Body>
        <div style={{marginTop: ''}}>
        {(conformButtonText || isCancelButton || deleteButtonText) && (
          <Footer>
            {conformButtonText && (
              <CustomButton
                title={conformButtonText}
                onClick={()=>updateData(paymentDate, transactionId)}
                // width="120px"
                loading={loading}
                disable={loading}
              />
            )}

            {isCancelButton && (
              <CustomButton
                title={cancelButtonText}
                type="secondary"
                onClick={onCancel || handleClose}
                // width="120px"
              />
            )}
            {deleteButtonText && (
              <CustomButton
                title={deleteButtonText}
                type="secondary"
                onClick={deleteData}
                // width="120px"
                del={true}
              />
            )}

          </Footer>
        )}
        </div>
      </div>
    </Modal>
  );
};

export default ReconcileModal;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #059bbf;
  padding: 10px 20px 20px 20px;
  h3 {
    color: #059bbf;
  }
  span {
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 40px;
  p {
    color: grey;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: "60px";
  margin-top: auto;
  background-color: #f5f5f5;
  padding: 14px 15px 15px;
  border-top: 1px solid #059bbf;
`;
