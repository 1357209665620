import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PayrunService } from "../../_service";
import { useAppSelector } from "../../_app";
import styled from "styled-components";
import { CustomButton } from "../customButton";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";

const BulkFinalisation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const logFirebaseEvent = useFirebaseAnalytics();
  const user = useAppSelector((state) => state.user);

  const companyId = useAppSelector((state) => +state.user.defaultCompnayId);
  const [ids, setIds] = useState<any[]>([]);
  const [showActivity, setShowActivity] = useState(true);
  const [showPayslipValue, setShowPayslipValue] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [loading, setLoading] = useState<boolean>(false)

  const handleSelectItem = (id: any) => {
    const isSelected = ids.some((_) => _ == id);
    if (isSelected) {
      setIds((prev) => prev.filter((_) => _ != id));
    } else {
      setIds((prev) => [...prev, id]);
    }
  };

  const handleSelectAll = () => {
    state?.data?.map((item: any) => {
      if (isSelectAll) {
        setIds((prev) => prev.filter((_) => _ != item.id));
      } else {
        if (validateNetPay(item?.payslip_info?.net_pay)) {
          setIds((prev) => [...prev, item.id]);
        }
      }
    });
    setIsSelectAll(() => !isSelectAll);
  };

  const bulkFinalize = async () => {
    if (ids?.length > 0) {
      const formData = new FormData();
      formData.append("finalize", "true");
      formData.append("payslip_ids", ids.join(","));
      formData.append("company", `${companyId}`);
      setLoading(true);
      try {
        const res = await PayrunService._bulkPayslipFinalize(formData);
        if(res?.status >= 200 && res?.status < 300){
          console.log("Bulk Finalize");
          // firebase log here
        let key = "finalize";
        let data = {
          user_id: user.id,
          name: user.name,
          // device_fingerprint: "using_client_js",
          event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
          platform: "web",
        };
        logFirebaseEvent(key, data);
        setLoading(false);

        }
      } catch (err) {
        setLoading(false);
        Notify(Constant.common.something_went_wrong, 0);
      }
      navigate("/dashboard/payment_runs");
    }
  };

  // checking if netPay is great than or equals to zero
  const validateNetPay = (netPay: any) => {
    if (
      !Number.isNaN(
        parseFloat(Utils.getPriceFromString(netPay))
      ) &&
      parseFloat(Utils.getPriceFromString(netPay)) >= 0
    ) {
      return true;
    }
  }

  return (
    <div>
      <h2
        style={{ color: "#476575", fontSize: "1.5rem", marginBottom: "0.5rem" }}
      >
        Bulk Finalisation - Monthly - {state?.date}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch>
              <input
                type="checkbox"
                defaultChecked={showActivity}
                onClick={() => setShowActivity((prev) => !prev)}
              />
              <span></span>
            </Switch>
            <p>Show recent activity</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch>
              <input
                type="checkbox"
                defaultChecked={showPayslipValue}
                onClick={() => setShowPayslipValue((prev) => !prev)}
              />
              <span></span>
            </Switch>
            <p>Show payslip values</p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <CustomButton
            type="secondary"
            title="Cancel"
            onClick={() => {
              navigate("/dashboard/payment_runs");
            }}
          />
          <CustomButton title="Finalise" onClick={bulkFinalize} disable={loading} loading={loading} />
        </div>
      </div>

      <h3 style={{ color: "#476575", fontSize: "1.5rem", margin: "0.5rem 0" }}>
        Payslips Ready for Finalisation
      </h3>
      <Table>
        <thead>
          <tr>
            <th>
              <input
                checked={isSelectAll}
                onClick={() => handleSelectAll()}
                onChange={() => handleSelectAll()}
                type="checkbox"
                style={{ width: "15px", marginRight: 5 }}
              />
              Select All
            </th>
            <th>Name</th>
            <th>Number</th>
            <th>Pay Date</th>
            <th>Net Pay</th>
            {showActivity && <th>Recent Activity</th>}
          </tr>
        </thead>
        <tbody>
          {state?.data?.sort((a: any, b: any) =>
            a.employee?.user?.name.localeCompare(b.employee?.user?.name)
          )
          ?.map((item: any) => (
            <tr key={item.id}>
              <td>
                <input
                  checked={ids.some((_) => _ == item.id)}
                  onClick={() => handleSelectItem(item.id)}
                  onChange={undefined}
                  type="checkbox"
                  style={{ width: "15px" }}
                  disabled={!validateNetPay(item?.payslip_info?.net_pay)}
                />
              </td>
              <td
                style={{
                  color:
                    validateNetPay(item?.payslip_info?.net_pay) ? "black" : "gray",
                }}
              >
                {item?.employee?.user?.name}
              </td>
              <td
                style={{
                  color:
                    validateNetPay(item?.payslip_info?.net_pay) ? "black" : "gray",
                }}
              >
                {item?.id}
              </td>
              <td
                style={{
                  color:
                    validateNetPay(item?.payslip_info?.net_pay) ? "black" : "gray",
                }}
              >
                {item?.payslip_date}
              </td>
              <td
                style={{
                  color:
                    validateNetPay(item?.payslip_info?.net_pay) ? "black" : "gray",
                }}
              >
                {item?.payslip_info?.net_pay || ""}
              </td>
              {showActivity && <td></td>}
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <div style={{ marginTop: "2rem", display: "flex" }}>
        <CustomButton
          type="secondary"
          title="Cancel"
          onClick={() => {
            navigate("/dashboard/payment_runs");
          }}
        />
        <CustomButton title="Finalise" onClick={bulkFinalize} />
      </div> */}
    </div>
  );
};

export default BulkFinalisation;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  margin-right: 10px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + span {
      background-color: #2196f3;
    }
    &:checked + span:before {
      transform: translateX(14px);
    }
    &:focus + span {
      box-shadow: 0 0 1px #2196f3;
    }
  }
  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 13px;
      left: 2px;
      bottom: 1px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: #e8e8e8;
  border: 1px solid #000;
  th,
  td {
    text-align: center;
    padding: 0.3rem;
    border: 1px solid #000;
    font-size: .8125rem;
  }
`;
