import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import "./add-company.css";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import { useAppSelector } from "../../_app";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { Utils } from "../../utils";
import styled from "styled-components";

interface PayslipModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const customStyles = {
  content: {
    // top: "45%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    // border: "1px solid #283a44",
    // borderRadius: "10px",
  },
};

const PayslipModalPDF = (props: PayslipModalProps) => {
  const { handleClose, open, data } = props;

  const employee = useAppSelector((state) => state.employee);
  const componentRef = useRef<any>();
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div className="payslip-modal">
        <div className="header">
          <div>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    color: "#059bbf",
                    padding: ".2rem",
                    borderRadius: ".25rem",
                    cursor: "pointer",
                  }}
                >
                  <IconContext.Provider
                    value={{ color: "#ff0000", size: "30px" }}
                  >
                    <FaFilePdf />
                  </IconContext.Provider>
                </p>
              )}
              content={() => componentRef.current}
            />
          </div>
          <p className="close-icon" onClick={handleClose}>
            <IconContext.Provider value={{ color: "#6c757d", size: "30px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </p>
        </div>
        <hr
          style={{
            color: "#6c757d",
            marginBottom: "1rem",
            marginTop: ".25rem",
          }}
        />
        <div ref={componentRef} style={{ margin: "1rem" }}>
          <span
            style={{
              border: "1px solid red",
              position: "absolute",
              right: 20,
              color: "red",
              padding: "2px",
            }}
          >
            DRAFT
          </span>
          <p style={{ textAlign: "center", marginBottom: "1rem" }}>
            {employee?.company_info?.name}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "70%",
                marginBottom: "2rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <b style={{ width: "200px" }}>Employee Name</b>
                <p>{`${employee?.currentEmployee.name}`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <b style={{ width: "200px" }}>Period</b>
                <p>
                  {data?.payslip_date?.start || ""} -{" "}
                  {data?.payslip_date?.end || ""}
                </p>
              </div>
              {employee?.employee_info?.nric && (
                <div style={{ display: "flex" }}>
                  <b style={{ width: "200px" }}>NRIC</b>
                  <p>{employee?.employee_info?.nric}</p>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <b style={{ width: "200px" }}>Employment Date</b>
                <p>{employee?.employee_info?.date_of_appointment}</p>
              </div>
            </div>
            <div style={{ textAlign: "right", width: "300px" }}>
              {employee?.company_info?.address}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {Object.entries(props?.data || {}).map(
              (item: any, index: number) => {
                return ["payslip_date", "net_pay", "cpf_details"].includes(
                  item[0],
                ) ? null : (
                  <Container>
                    <Row>
                      <b>{Utils.formateStringWithUnderscore(item[0])}</b>
                      <b>{item[1]?.total}</b>
                    </Row>
                    <Hr />
                    {item[1]?.data?.map((_item: any) => {
                      return (
                        <Row>
                          <p>{_item?.name || ""}</p>
                          <p>{_item?.value || ""}</p>
                        </Row>
                      );
                    })}
                  </Container>
                );
              },
            )}
            {Object.entries(employee?.employee_leave || {})?.length > 0 && (
              <Container>
                <Row>
                  <b>Leave Type</b>
                  <b>Balance</b>
                  <b>Adjmt.</b>
                  <b>Taken</b>
                  <b>Sched.</b>
                </Row>
                <Hr />
                {Object.entries(employee?.employee_leave || {})?.map(
                  (_item: any) => {
                    return (
                      <Row>
                        <p>{_item[0] || ""}</p>
                        <p>{_item[1]?.Balance?.toFixed(2)}</p>
                        <p>{_item[1]?.Adjmt?.toFixed(2)}</p>
                        <p>{_item[1]?.Taken?.toFixed(2)}</p>
                        <p>{_item[1]?.Sched?.toFixed(2)}</p>
                      </Row>
                    );
                  },
                )}
              </Container>
            )}
            <div
              style={{
                width: "95%",
                marginTop: "2rem",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                border: "none",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>
                Net Pay{" "}
                <span style={{ marginLeft: "2rem" }}>
                  {"$ "}
                  {props?.data?.net_pay}
                </span>
              </b>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PayslipModalPDF;

const Container = styled.div`
  width: 45%;
  margin-right: 5%;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 50%;
`;
const Hr = styled.hr`
  color: #000;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;
