import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  CustomNumberField,
  NavigateBack,
  CustomSelectField,
  Loader,
} from "../../../components";
import { Heading } from "../AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { ServiceCodeService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReportSectionHeading } from "../../reports/ReportFilterPageStyles";
import { useAppSelector } from "../../../_app";
import { LoaderContainer } from "../../../CommonStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";

const GenerateInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = new Date();
  let isViewOnly = location?.state?.isViewOnly;

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );


  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [fixedAmount, setFixedAmount] = useState<string>("");
  const [variableAmount, setVariableAmount] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearOptions = Utils.getDefaultYearRange();

  // const [month, setMonth] = useState<string>(`${1}`);
  // const [year, setYear] = useState<string>(`${moment().year()}`);

  const [year, setYear] = useState<string>( location?.state?.isViewOnly ? location?.state?.date?.split('-')[0] :
  moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD")?.split("-")[0] || date.getFullYear()
  );
  const [month, setMonth] = useState<string>( location?.state?.isViewOnly ? location?.state?.date?.split('-')[1] :
    `${moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD")?.split("-")[1] || date.getMonth()}`
  );

  console.log("currentPayrunDate", currentPayrunDate, moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD"))

  //   const addServiceCode = async () => {
  //     try {
  //       const { status } = await ServiceCodeService._addServiceCode({
  //         name: servicecode,
  //         fixed_amount: fixedAmount,
  //         variable_amount: variableAmount,
  //       });
  //       if (status === 201) {
  //         Notify("Service code added successfully", 1);
  //         navigate("/dashboard/manage-service-code");
  //       }
  //     } catch (error: any) {
  //       if (error?.response?.data?.name?.message) {
  //         Notify(error.response.data.name.message, 0);
  //       } else {
  //         Notify(Constant.common.something_went_wrong, 0);
  //       }
  //     }
  //   };

  //   const editServiceCode = async () => {
  //     try {
  //       const { status } = await ServiceCodeService._editServiceCode(
  //         {
  //           name: servicecode,
  //           fixed_amount: fixedAmount,
  //           variable_amount: variableAmount,
  //         },
  //         location.state.id
  //       );
  //       if (status === 200) {
  //         Notify("Service code edited successfully", 1);
  //         navigate("/dashboard/manage-service-code");
  //       }
  //     } catch (error: any) {
  //       if (error?.response?.status === 400) {
  //         if (error?.response?.data?.name?.message) {
  //           Notify(error.response.data.name.message, 0);
  //         } else {
  //           Notify(Constant.common.something_went_wrong, 0);
  //         }
  //       } else {
  //         Notify(Constant.common.something_went_wrong, 0);
  //       }
  //     }
  //   };

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "20%",
    },
    {
      name: "Fixed amount",
      width: "20%",
    },
    {
      name: "Variable Amount",
      width: "20%",
    },
    {
      name: "No. of Employees",
      width: "20%",
    },
    {
      name: "Total",
      width: "20%",
    },
  ];

  const getInvoiceData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceData(
        companyId,
        `01-${month}-${year}`
      );

      if (status >= 200 && status < 300) {
        setInvoiceData(data?.data);
      }
    } catch (e:any) {
      if (e?.response?.data?.message ) {
        Notify(e?.response?.data?.message , 0);
      }
        else {
          Notify(Constant.common.something_went_wrong, 0);
        }
    }
    setIsLoading(() => false);
  };

  const getInvoiceViewData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._viewInvoice(
        location?.state?.id
      );

      if (status >= 200 && status < 300) {
        console.log("view res", data?.data);
        setInvoiceData(data?.data);
      }
    } catch (e:any) {
      if (e?.response?.data?.message ) {
        Notify(e?.response?.data?.message , 0);
      }
        else {
          Notify(Constant.common.something_went_wrong, 0);
        }
    }
    setIsLoading(() => false);
  };

  const handleSave = async (action: any) => {
    if (!invoiceNumber) {
      Notify("Please enter the invoice number", 0);
      return null;
    }

    let payload = {
      company: companyId,
      date: `${year}-${month}-01`,
      number: invoiceNumber,
      json_data: invoiceData?.invoice_data,
    };

    try {
      const { status } = await ServiceCodeService._saveInvoice(action, payload);

      if (status >= 200 && status < 300) {
        Notify(
          !action
            ? "Invoice Created Successfully"
            : "Invoice Saved and Sent Successfuly",
          1
        );
        navigate("/dashboard/customer-invoice");
      }
    } catch (e: any) {
      if (e?.response?.data?.message || e?.response?.data?.number[0]) {
        console.log("error", e?.response?.data?.message);
        Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  // Resend and Download
  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Resent Successfully", 1);
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  useEffect(() => {
    if(isViewOnly) {
      setInvoiceData((prev:any)=>{
        return {...prev, invoice_data: {} }
      });
      getInvoiceViewData();
    } else {
      setInvoiceData((prev:any)=>{
        return {...prev, invoice_data: {} }
      });
      getInvoiceData();
    }
  }, [month, year, isViewOnly]);

  let dataToMap = isViewOnly ? invoiceData?.invoice_data?.json_data?.items : invoiceData?.invoice_data?.items;
  let grandTotal = isViewOnly ? invoiceData?.invoice_data?.json_data?.grand_total : invoiceData?.invoice_data?.grand_total;

  console.log("theInvoiceData", invoiceData);
  console.log("locationState", location?.state)

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  console.log("inputInv", invoiceNumber);

  if(!location?.state?.id && !invoiceData?.is_service_subscribed) {
    return  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "5rem",
      flexDirection: "column",
      gap: "0.75rem",
      padding: '0.5rem',
    }}
  >
    <p
      style={{
        color: "rgb(33, 37, 41)",
        fontWeight: 600,
        fontSize: "1.25rem",
      }}
    >{`There is no service code attached to this company or there is no service enabled.`}</p>
    <p
      style={{
        color: "rgb(33, 37, 41)",
        fontWeight: 600,
        fontSize: "1.25rem",
        marginBottom: '1rem'
      }}
    >{`Please click the button below to go to the Employer Details page and add/enable a service.`}</p>

    <CustomButton
      title="Edit Employer Details"
      onClick={() => navigate("/dashboard/app-settings/employer-details")}
    />
  </div>
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <NavigateBack />
        <Heading>{location?.state?.isViewOnly ? `Invoice - ${moment(invoiceData?.invoice_data?.date).format('D MMMM YYYY')}` : "Generate Invoice"}</Heading>
      </div>

      {!location?.state?.isViewOnly && <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          gap: "1rem",
          margin: "0 1rem",
        }}
      >
        <p
          style={{
            // margin: "0rem 1rem",
            color: "rgb(71, 101, 117)",
            fontWeight: 600,
            fontSize: "1rem",
            whiteSpace: "nowrap",
          }}
        >
          Select Month & Year:
        </p>
        <CustomSelectField
          value={month}
          onChangeOption={(value) => setMonth(value)}
          option={monthsOptions.map((month) => ({
            key: month.key,
            value: month.value,
          }))}
          disabled={isLoading}
          isDate={true}
        />

        <CustomSelectField
          value={year}
          onChangeOption={(value) => setYear(value)}
          option={yearOptions.map((year) => ({
            key: year,
            value: year,
          }))}
          disabled={isLoading}
          isDate={true}
        />
      </div>}

      {/* {location?.state?.isViewOnly && 
      <h2 style={{
        margin: "0 1rem",
        color: 'rgb(71, 101, 117)'
            }}>
        {moment(location?.state?.date,'yyyy-mm-dd',).format('MMMM YYYY')}
        </h2>} */}

      {/* Add address here */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          alignItems: "start",
          margin: "0 1rem",
          marginTop: '1rem'
        }}
      >
        <Heading>{"Company Info"}</Heading>

        <div
          style={{
            // marginLeft: "1rem",
            display: "flex",
            justifyItems: "start",
            alignItems: "center",
            gap: "1.5rem",

            color: "#495057",
          }}
        >
          <p
            style={{
              width: "5rem",
            }}
          >
            Company:
          </p>
          <p>{invoiceData?.company_data?.name}</p>
        </div>

        <div
          style={{
            // marginLeft: "1rem",
            display: "flex",
            justifyItems: "start",
            alignItems: "center",
            gap: "1.5rem",

            color: "#495057",
          }}
        >
          <p
            style={{
              width: "5rem",
            }}
          >
            Name:
          </p>
          <p>{invoiceData?.company_data?.authorised_person_name}</p>
        </div>

        <div
          style={{
            // marginLeft: "1rem",
            display: "flex",
            justifyItems: "start",
            alignItems: "center",
            gap: "1.5rem",

            color: "#495057",
          }}
        >
          <p
            style={{
              width: "5rem",
            }}
          >
            Email:
          </p>
          <p>{invoiceData?.company_data?.authorised_person_email}</p>
        </div>

        <div
          style={{
            // marginLeft: "1rem",
            display: "flex",
            justifyItems: "start",
            alignItems: "start",
            gap: "1.5rem",

            color: "#495057",
          }}
        >
          <p
            style={{
              width: "5rem",
            }}
          >
            Address:
          </p>

          <div>
            <p>{invoiceData?.company_data?.address_l1}</p>
            <p>{invoiceData?.company_data?.address_l2}</p>
            <p>{invoiceData?.company_data?.address_l3}</p>
          </div>
        </div>

        {/* invoice number for viewOnly mode */}
        {location?.state?.isViewOnly && <div
          style={{
            display: "flex",
            justifyItems: "start",
            alignItems: "center",
            gap: "1.5rem",

            color: "#495057",
            fontWeight: 700,
            marginBottom: '1rem'
          }}
        >
          <p
            style={{
              width: "5rem",
            }}
          >
            Invoice Number:
          </p>
          <p>{location?.state?.number}</p>
        </div>}
      </div>

      {!location?.state?.isViewOnly && <div style={{ margin: "1.5rem 1rem" }}>
        <CustomNumberField
          title="Invoice Number"
          value={invoiceNumber}
          onChangeText={setInvoiceNumber}
          width={"fit-content"}
          placehoder="000#"
        />
      </div>}

      {/* Table */}

      {/* <div style={{ marginBottom: "30px" }}>
        <IRASTable>
          <TableHeader
            headerColumns={serviceCodeTableHeaders}
            variant="filled"
            styles={{ padding: "7px" }}
          />
          <tbody>
            {invoiceData?.invoice_data?.items.length > 0 &&
              invoiceData?.invoice_data?.items?.map((service: any) => (
                <TableRow key={service.id}>
                  <td style={{ padding: "5px" }}>{service.name}</td>
                  <td style={{ padding: "5px" }}>{service.fixed_amount}</td>
                  <td style={{ padding: "5px" }}>{service.variable_amount}</td>
                  <td style={{ padding: "5px" }}>{service.no_employees}</td>
                  <td style={{ padding: "5px" }}>{service.total_amount}</td>
                </TableRow>
              ))}
          </tbody>
        </IRASTable>
      </div> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                background: "#b8daff",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Service Code
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Fixed Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Variable Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                {" "}
                No. of Employees
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                {" "}
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToMap?.length > 0 && dataToMap?.map((item: any) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  // background: "#e9f3ff",
                }}
              >
                <TableCell component="th" scope="item" align="left">
                  {item.name}
                </TableCell>
                <TableCell align="left">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.fixed_amount || ""
                )}`}</TableCell>
                <TableCell align="left">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.variable_amount || ""
                )}`}</TableCell>
                <TableCell align="left">{`${(
                  item?.no_of_employees || ""
                )}`}</TableCell>
                <TableCell align="left">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.total_amount || ""
                )}`}</TableCell>
              </TableRow>
            ))}
            {grandTotal && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={4}
                  align="right"
                >{`Grand Total:`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="left"
                >{`${Utils.getCommanSepartedNumericStringWithDollar(
                  grandTotal || ""
                )}`}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Buttons */}

      {dataToMap?.length > 0 && !location?.state?.isViewOnly && <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: "1.5rem",
          marginLeft: "auto",
          width: "100%",
        }}
      >
        <CustomButton title="Create" onClick={() => handleSave(null)} loading={isLoading} disable={isLoading} />
        <CustomButton
          title="Save & Send"
          onClick={() => handleSave("save-send")}
          loading={isLoading}
          disable={isLoading}
        />
      </div>}

      {dataToMap?.length > 0 && location?.state?.isViewOnly && <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: "1.5rem",
          marginLeft: "auto",
          width: "100%",
        }}
      >
        <CustomButton title="Download PDF" onClick={() => handlePDFDownload(location?.state?.id)} loading={isLoading} disable={isLoading} />
        <CustomButton
          title="Resend"
          onClick={() => handleResend(location?.state?.id)}
          loading={isLoading}
          disable={isLoading}
        />
      </div>}
    </div>
  );
};

export default GenerateInvoice;
