import apiClient from "./BaseService";
import { URL } from "../config";

const _getServiceCodeList = async () => apiClient.get(URL.SERVICE_CODES);
const _addServiceCode = async (data: any) => {
    return apiClient.post(URL.SERVICE_CODES, data)
}
const _deleteServiceCode = async (serviceId: any) => {
    return apiClient.delete(URL.SERVICE_CODES+ serviceId + '/')
}
const _editServiceCode = async (data: any, serviceId: any) => {
    return apiClient.patch(URL.SERVICE_CODES+ serviceId + '/', data)
}

const _manageService = async (data: any, action: any) => {
    return apiClient.post(URL.MANAGE_SERVICE + action, data)
}

const _getInvoiceList = async (companyId: string) =>
    apiClient.get(`${URL.GET_INVOICE_LIST}${companyId}`);

const _getInvoiceData = async (companyId: string, date: string) =>
    apiClient.get(`${URL.GET_INVOICE_DATA}${companyId}&date=${date}`);

const _resendInvoice = async (invoiceId: any) => {
    return apiClient.post(`${URL.RESEND_INVOICE}${invoiceId}/resend/`)
}

// action: 'save-send' or null
const _saveInvoice = async (action: any, data:any) => {
    return apiClient.post(`${URL.SAVE_INVOICE}${action}`, data);
}

const _reconcileInvoice = async (data:any) => {
    return apiClient.post(`${URL.RECONCILE_INVOICE}`, data);
}

const _downloadInvoice = async (invoiceId: any) => {
    return apiClient.get(`${URL.DOWNLOAD_INVOICE}${invoiceId}/pdf/`)
}

const _viewInvoice = async (invoiceId: any) => {
    return apiClient.get(`${URL.VIEW_INVOICE}${invoiceId}/`)
}

export const ServiceCodeService = {
    _getServiceCodeList,
    _addServiceCode,
    _deleteServiceCode,
    _editServiceCode,
    _manageService,
    _getInvoiceList,
    _downloadInvoice,
    _resendInvoice,
    _getInvoiceData,
    _saveInvoice,
    _viewInvoice,
    _reconcileInvoice
};
