import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import { FaReceipt } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  CustomButton,
  DeactivateModal,
  Loader,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { Heading } from "../AppSettingsStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceCodeService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import moment from "moment";
import ReconcileModal from "./ReconcileModal";

const CustomerInvoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceCodes, setServiceCodes] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [serviceCodeToDelete, setServiceCodeToDelete] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>(null);
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState<string>("");
  const [showReconcileModal, setShowReconcileModal] = useState<boolean>(false)

  const navigate = useNavigate();
  const location = useLocation();


  const user = useAppSelector((state) => state.user);

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const serviceCodeTableHeaders = [
    {
      name: "Invoice No.",
      width: "10%",
    },
    // {
    //   name: "Invoice No.",
    //   width: "16%",
    // },
    // {
    //   name: "Service Code",
    //   width: "16%",
    // },
    {
      name: "Month & Year",
      width: "10%",
    },
    {
      name: "Service Codes",
      width: "16%",
    },
    // {
    //   name: "Reconcile",
    //   width: "20%",
    // },
    {
      name: "Grand Total",
      width: "16%",
    },
    {
      name: "Status",
      width: "10%",
    },
    // {
    //   name: "Resend",
    //   width: "10%",
    // },
    {
      name: "Action",
      width: "10%",
    },
  ];

  const getInvoiceListing = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceList(
        companyId
      );

      if (status === 200) {
        setInvoiceData(data?.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Resent Successfully", 1);
      }
    } catch (error: any) {
      if (error?.response?.status) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  useEffect(() => {
    getInvoiceListing();
  }, []);

  const handleReconcile = async (date:string, transactionId: string ) => {
    let payload = {
      "invoice_id": selectedInvoiceId,
      "payment_received_date": date,
      "transaction_id": transactionId
  }
  console.log("thePayload", payload)

  try {
    const { data, status } = await ServiceCodeService._reconcileInvoice(
      payload
    );

    if (status === 200) {
      Notify("Reconcilation Successful!", 1);
      setShowReconcileModal(false);
      setSelectedInvoiceId(null);
      setSelectedInvoiceAmount("");
      getInvoiceListing();
    }
  } catch (error:any) {
    if (error.response.data?.message) {
      Notify(error.response.data?.message, 0);
    } else {
      Notify(Constant.common.something_went_wrong, 0);
    }
  }

  }


  console.log("selectedId out", selectedInvoiceId)

  

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
              width: "100%"
            }}
          >
            <Heading>Customer Invoice</Heading>
            {user.permission === "full" && 
              <div style={{
                display:  'flex',
                alignItems: 'center'
              }}>
                <CustomButton
                title="Auto Reconcile"
                type="secondary"
                onClick={() => null}
              />
              <CustomButton
                title="Generate Invoice"
                type="secondary"
                onClick={() => navigate("generate")}
              />
              </div>
              
            }
            
          </div>
          <IRASTable style={{
            width: "100%"
          }}>
            <TableHeader
              headerColumns={serviceCodeTableHeaders}
              variant="filled"
              styles={{ padding: "5px", textAlign: "left" }}
            />
            <tbody>
              {invoiceData?.length > 0 &&
                invoiceData
                  .sort((a: any, b: any) => a.id - b.id)
                  .map((item: any, index: any) => (
                    <TableRow style={{cursor: 'pointer'}} key={item.id}>
                      <td onClick={() => navigate("generate", {
                      state: {...item, 'isViewOnly' : true},
                    })} style={{
                        textAlign: 'left',
                        paddingLeft: '1.5rem'
                      }}>{item?.number || ""}</td>
                      {/* <td>{item?.number || ""}</td>
                      <td>{item?.json_data?.items[0]?.name || ""}</td> */}
                      <td onClick={() => navigate("generate", {
                      state: {...item, 'isViewOnly' : true},
                    })} style={{
                        padding: '0.3125rem'
                      }}>{item?.date && ` ${moment(item?.date, "YYYY-MM-DD").format("MMMM")} ${item?.date?.split("-")[0]}`}</td>
                      <td onClick={() => navigate("generate", {
                      state: {...item, 'isViewOnly' : true},
                    })}  style={{
                        padding: '0.3125rem'
                      }}>
                        {item?.json_data?.items?.map((x:any)=> <li>{`${x?.name}`}</li>)}
                      </td>

                      <td onClick={() => navigate("generate", {
                      state: {...item, 'isViewOnly' : true},
                    })} style={{
                        padding: '0.3125rem'
                      }}>{Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total || "")}</td>
                      <td onClick={() => navigate("generate", {
                      state: {...item, 'isViewOnly' : true},
                    })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform:'capitalize'
                        }}>{item?.status || ""}</p>
                      </td>

                      

                      <td
                        style={{
                          display:'flex',
                          gap: '1rem',
                          alignContent: 'center',
                          textAlign: "center",
                          padding: '0.3125rem'
                        }}
                      >
                        <Tooltip text="Reconcile">
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          width="30px"
                          title={""}
                          onClick={item?.status === "reconciled" ? ()=>null : () => {
                            setSelectedInvoiceId(()=>item?.id);
                            setSelectedInvoiceAmount(()=> Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total))
                            setShowReconcileModal(true)
                          }}
                          icon={<FaReceipt />}
                          disable={item?.status === "reconciled"}
                        />
                        </Tooltip>
                        <Tooltip text="Resend">
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          width="30px"
                          title={""}
                          onClick={() => handleResend(item?.id)}
                          icon={<IoIosSend />}
                        />
                        </Tooltip>
                        <Tooltip text="Download">
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          width="30px"
                          title={""}
                          onClick={() => handlePDFDownload(item?.id)}
                          icon={<MdOutlineFileDownload />}
                        />
                        </Tooltip>
                      </td>
                    </TableRow>
                  ))}
            </tbody>
          </IRASTable>

          <ReconcileModal heading="Reconcile" amount={selectedInvoiceAmount} open={showReconcileModal} handleClose={()=>{
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} onCancel={()=>{
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} updateData={handleReconcile} />
        </>
      )}
    </div>
  );
};

export default CustomerInvoice;

