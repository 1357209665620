import { useEffect, useState } from "react";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { ActionColumn, ActionFlex, TableColumn } from "./styles";
import { AuthService, UserService } from "../../_service";
import DeactivateModal from "../../components/modals/DeactivateModal";
import { Notify } from "../../utils";
import SuccessButton from "../../components/buttons/SuccessButton";
import { CustomButton, Loader } from "../../components";
import { LoaderContainer } from "../../CommonStyles";
import { TableHeader } from "../../components/table";
import ManageTableHeading from "../../components/heading/ManageTableHeading";
import { SearchField, TablePaginationHeader } from "../dashboard/component";
import { AiFillDelete, AiFillEdit, AiOutlineSync } from "react-icons/ai";
import { Constant } from "../../config";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableRow } from "../payslip/PayslipStyles";
import { FaTelegramPlane } from "react-icons/fa";
import { useAppSelector } from "../../_app";
import cloneDeep from "lodash.clonedeep";

interface UserData {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  groups: [{ name: string }];
  full_name: string;
  is_superuser: boolean;
}

const ManageUsers = () => {
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const navigate = useNavigate();
  const [isDeactivateModalActive, setIsDeactivateModalActive] =
    useState<boolean>(false);
  const [isReactivateModalActive, setIsReactivateModalActive] =
    useState<boolean>(false);
  const [isDeleteModalActive, setIsDeleteModalActive] =
    useState<boolean>(false);
  const [activeUserId, setActiveUserId] = useState<number>(0);
  const [userActiveEmail, setUserActiveEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isResendActivationLink, setIsResendActivationLink] =
    useState<boolean>(false);

  const appUser = useAppSelector((state) => state.user);
  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: 0,
  });

  const tableHeaders = [
    {
      name: "E-mail",
      width: "20%",
    },
    {
      name: "Name",
      width: "30%",
    },
    {
      name: "Actions",
      width: "20%",
    },
    {
      name: "Roles/Permissions",
    },
  ];

  const changeUserArrays = (updatedArray: any) => {
    setAllUsers(() => updatedArray);
    setFilteredUsers(() => updatedArray);
  };

  const getAllUsers = async () => {
    setIsLoading(() => true);
    try {
      const res = await UserService._getAllUsers();
      if (res.status === 200) {
        setAllUsers(() =>
          res.data.map((data: any) => ({
            ...data,
            full_name: `${data?.first_name || ""} ${data?.last_name || ""}`,
          })),
        );
        setFilteredUsers(() =>
          res.data.map((data: any) => ({
            ...data,
            full_name: `${data?.first_name || ""} ${data?.last_name || ""}`,
          })),
        );
        setPageInfo((info: any) => ({
          ...info,
          totalLength: res.data.length,
        }));
      }
    } catch (e: any) {
      Notify("Some Error occurred", 0);
    }
    setIsLoading(() => false);
  };

  const deactivateUser = async () => {
    try {
      const res = await UserService._deactivateUser({ id: activeUserId });
      if (res.status === 200) {
        Notify("User Deactivated Successfully");

        const updatedUserArray = allUsers.map((obj) => {
          if (obj.id === activeUserId) {
            return { ...obj, is_active: false };
          }
          return obj;
        });
        changeUserArrays(updatedUserArray);
      }
    } catch (e: any) {
      Notify("Some Error occurred");
    }

    setIsDeactivateModalActive(() => false);
  };

  const reactivateUser = async () => {
    try {
      const res = await UserService._reactivateUser({ id: activeUserId });
      if (res.status === 200) {
        Notify("User Activated Successfully");

        const updatedUserArray = allUsers.map((obj) => {
          if (obj.id === activeUserId) {
            return { ...obj, is_active: true };
          }
          return obj;
        });
        changeUserArrays(updatedUserArray);
      }
    } catch (e: any) {
      Notify("Some Error occurred");
    }

    setIsReactivateModalActive(() => false);
  };

  const resendActivationLink = async () => {
    try {
      const { status } = await AuthService._forgotPassword(userActiveEmail);

      if (status === 201) {
        Notify("Activation link sent successfully, please check your email", 1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsResendActivationLink(() => false);
  };

  const deleteUser = async () => {
    try {
      const res = await UserService._deleteUser(activeUserId);
      if (res.status >= 200 && res.status < 300) {
        Notify("User Deleted Successfully", 1);
        const deletedUserArray = allUsers.filter(
          (obj) => obj.id !== activeUserId,
        );
        changeUserArrays(deletedUserArray);
      }
    } catch (error) {
      Notify("Something wrong happened, please try again");
    }
    setIsDeleteModalActive(() => false);
  };

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > allUsers.length
        ? allUsers.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const allUsersObj = allUsers;
    // user.full_name.toLowerCase().includes(searchText.toLowerCase().trim()),
    const applyFilter = allUsersObj.filter((user:any) =>
    ["full_name", "email"].some((item:string)=> user[item]?.toLowerCase()?.includes(searchText?.toLowerCase().trim()))
    );
    setFilteredUsers(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  const changeSearchText = (value: string) => {
    setSearchText(() => value);
    // const users = allUsers;
    // const applyFilter = users.filter((user) =>
    //   user.full_name.toLowerCase().includes(value.toLowerCase().trim()),
    // );

    const users = cloneDeep(allUsers); 
    const applyFilter = users.filter((user: any) => 
      ["full_name", "email"].some((item:string)=> user[item]?.toLowerCase()?.includes(value?.toLowerCase().trim()))
    )

    setPageInfo((info: any) => ({
      ...info,
      totalLength: applyFilter.length,
    }));

    setFilteredUsers(() => applyFilter);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <ManageTableHeading
            title="Users"
            buttonTitle="Add new user"
            onClick={() => navigate("new")}
          />
          <SearchField value={searchText} onChangeText={changeSearchText} />
          <TablePaginationHeader
            pageInfo={pageInfo}
            name="Users"
            handlePageValueChange={handlePageChange}
          />
          <div>
            <IRASTable style={{ width: "100%" }}>
              <TableHeader headerColumns={tableHeaders} variant="filled" />
              {filteredUsers?.length > 0 ? (
                filteredUsers.map((user) => (
                  <TableRow key={user?.email}>
                    <TableColumn>
                      <p style={{ overflow: "hidden" }}>{user?.email || ""}</p>
                    </TableColumn>
                    <TableColumn>
                      <p style={{ overflow: "hidden" }}>
                        {user?.full_name || ""}
                      </p>
                    </TableColumn>
                    {!user?.is_superuser ? <ActionColumn>
                      <ActionFlex>
                        {user.is_active && appUser.permission === "full" ? (
                          <CustomButton
                            onClick={() => {
                              setIsDeactivateModalActive(() => true);
                              setActiveUserId(() => user.id);
                            }}
                            title=""
                            width="30px"
                            size="small"
                            icon={<AiFillDelete />}
                            popoverText="De-Activate User"
                          />
                        ) : (
                          <SuccessButton
                            onClick={() => {
                              setIsReactivateModalActive(() => true);
                              setActiveUserId(() => user.id);
                            }}
                            title="Re-activate"
                          />
                        )}

                        {/* <CustomButton
                          onClick={() => {
                            setIsDeleteModalActive(() => true);
                            setActiveUserId(() => user.id);
                          }}
                          title="Delete"
                          type="secondary"
                          del={true}
                          width="70px"
                          size="small"
                        /> */}
                        {user.is_active && appUser.permission === "full" && (
                          <CustomButton
                            onClick={() =>
                              navigate("edit", {
                                state: {
                                  userId: user.id,
                                },
                              })
                            }
                            title=""
                            popoverText="Change Permissions or Restrict Company"
                            width="30px"
                            size="small"
                            type="secondary"
                            icon={<AiFillEdit />}
                          />
                        )}
                        {user.is_active && (
                          <CustomButton
                            size="small"
                            width="30px"
                            title=""
                            onClick={() => {
                              setUserActiveEmail(() => user.email);
                              setIsResendActivationLink(true);
                            }}
                            icon={<FaTelegramPlane />}
                            popoverText="Resend Activation Link"
                          />
                        )}
                      </ActionFlex>
                    </ActionColumn> : <div style={{width: "150px"}}></div>}
                    <TableColumn>
                      {user.is_superuser ? <span style={{fontWeight: "600"}}>SUPERUSER</span> : user.groups.map((group, index) => (
                        <span>
                          {group.name}
                          {index + 1 !== user.groups.length ? ", " : ""}
                        </span>
                      ))}
                    </TableColumn>
                  </TableRow>
                ))
              ) : (
                <p>No record for this action found.</p>
              )}
            </IRASTable>
            <DeactivateModal
              open={isDeactivateModalActive}
              closeModal={() => setIsDeactivateModalActive(() => false)}
              approveAction={deactivateUser}
            />

            {/* <DeactivateModal
              open={isDeleteModalActive}s
              closeModal={() => setIsDeleteModalActive(() => false)}
              approveAction={deleteUser}
              variant="delete"
            /> */}
            <DeactivateModal
              open={isReactivateModalActive}
              closeModal={() => setIsReactivateModalActive(() => false)}
              approveAction={reactivateUser}
              variant="reactivate"
            />
            <DeactivateModal
              open={isResendActivationLink}
              closeModal={() => setIsResendActivationLink(() => false)}
              approveAction={resendActivationLink}
              variant="resendActivationLink"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ManageUsers;
